import React from "react";
import "./content.css";

import Specialities from "./pages/specialities";
import Conventions from "./pages/conventions";
import Contacts from "./pages/contacts";
import WhoAreWe from "./pages/who-are-we";
import Slider from "./slider/slider";

interface IState {
    page: number;
}

export default class Content extends React.Component<{}, IState> {
    constructor(props: {}) {
        super(props);
        this.state = {
            page: 1
        };
    }

    public render() {
        return (
            <div className="content">
                <div className="navigation">
                    <button className={this.state.page == 1 ? "selected" : ""} onClick={() => this.changePage(1)}>A CLÍNICA</button>
                    <button className={this.state.page == 2 ? "selected" : ""} onClick={() => this.changePage(2)}>ESPECIALIDADES</button>
                    <button className={this.state.page == 3 ? "selected" : ""} onClick={() => this.changePage(3)}>CONTACTOS</button>
                </div>
                <div className="carousel">
                    <Slider />
                </div>
                <div className="page">
                    {this.getPage(this.state.page)}
                </div>
            </div>
        );
    }

    private changePage = (index: number) => {
        this.setState({
            page: index
        });
    }

    private getPage(index: number) {
        switch (index) {
            case 1:
                return <WhoAreWe changePage={this.changePage} />;
            case 2:
                return <Specialities />;
            case 3:
                return <Contacts />;
            case 4:
                return <Conventions />;
        }
    }
}