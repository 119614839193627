import React from "react";
import "./specialities.css";

const Specialities = () => (
    <>
        <div className="spec-title">ORTODONTIA</div>
        <div className="spec-text">Ortodontia é uma especialidade odontológica que corrige a posição dos dentes e dos ossos maxilares posicionados de forma inadequada. Se quiser melhorar o seu sorriso,
             a ortodontia pode ser a solução. Com a tecnologia atual, os aparelhos são mais confortáveis e menos visíveis do que nunca.</div>

        <div className="spec-title">IMPLANTOLOGIA</div>
        <div className="spec-text">A colocação de implantes é uma prática frequente desde a década de 80, sendo validada por inúmeros estudos clínicos que atestam a sua segurança e previsibilidade de 
            resultados. Esta técnica consiste na colocação de coroas provisórias em acrílico, fixas aos implantes, devolvendo ao paciente o conforto, a estética e a função.</div>

        <div className="spec-title">PERIODONTOLOGIA</div>
        <div className="spec-text">A periodontologia é um ramo da medicina dentária que se dedica às gengivas e aos tecidos de suporte do dente, uma das principais causas de perda de dentes nos adultos.</div>

        <div className="spec-title">CIRURGIA</div>
        <div className="spec-text">Especialidade da medicina dentária que trata todos os problemas que necessitam de solução cirúrgica, nomeadamente extracções de dentes que apresentem impossibilidade 
            de manutenção na cavidade oral, dentes com cáries muito extensas e não restauráveis, dentes sem suporte ósseo, dentes inclusos, entre outros.</div>

        <div className="spec-title">BRANQUEAMENTO</div>
        <div className="spec-text">Há muitas maneiras de clarear os dentes, desde cremes dentais com ação branqueadora a outros produtos que removem manchas superficiais a custos reduzidos. 
            Após uma década de pesquisas, os novos métodos de branqueamento provaram-se seguros e eficazes.</div>
    </>
);

export default Specialities;