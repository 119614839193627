import React from "react";
import "./main.css";

import Content from "./content";
import Footer from "./footer";

export default class Main extends React.Component {
    public render() {
        return (
            <div className="main">
                <div className="main-content"><Content /></div>
                <Footer />                
            </div>
        );
    }
}