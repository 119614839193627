import React from "react";
import "./who-are-we.css";

import conventionsImage from "../images/convencoesb.png";
import specialitiesImage from "../images/especialidadesb.png";
import contactsImage from "../images/ondeestamosb.png";

interface IProps {
    changePage: (index: number) => void;
}

const WhoAreWe = (props: IProps) => {
    return (
        <div className="waw">
            <div className="waw-title">QUEM SOMOS</div>
            <div className="waw-content">
                <div className="waw-text">
                    Localizados na Rua da Constituição, no centro da cidade do Porto, junto ao metro do Marquês, distinguimo-nos por reunirmos um serviço profissional a um cenário
                    acolhedor e familiar. Dedicamo-nos a cada caso específico com total cuidado e atenção, criando uma relação de proximidade e cumplicidade genuínas com o paciente,
                    fatores que nos distinguem dos demais. Sendo que o sorriso estabelece a primeira relação de empatia com o outro, cuide-o num local da sua confiança.
            </div>
                <div className="images">
                    <img src={specialitiesImage} onClick={() => props.changePage(2)} />
                    <img className="waw-conventions" src={conventionsImage} onClick={() => props.changePage(4)} />
                    <img src={contactsImage} onClick={() => props.changePage(3)} />
                </div>
            </div>
        </div>
    );
}

export default WhoAreWe;