import React from "react";
import "./conventions.css";

const Conventions = () => (
    <div className="conv-content">
        <p>No sentido de lhe prestarmos um melhor serviço, estabelecemos convenções com as seguintes entidades para que delas possa beneficiar:</p>
        <ul>
            <li>ADSE - <b>Exclusivo na Clínica Medicina Dentária Apúlia</b></li>
            <li>SAMS</li>
            <li>SAMS QUADROS</li>
            <li>MEDICARE</li>
            <li>MAXICARE</li>
        </ul>
    </div>
);

export default Conventions;