import React from "react";

import "./contacts.css";
import logo from "../images/logo.png";

const Contacts = () => (
    <div className="contacts-content">
        <div className="contacts">
            <div className="contacts-googlemap">
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3003.7497651772474!2d-8.605124984334532!3d41.16181131791117!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd2464585035d3b7%3A0x2493c3b88813de96!2sMarini+Medicina+Dent%C3%A1ria!5e0!3m2!1sen!2spt!4v1552604503755"></iframe>
            </div>
            <div className="contacts-details">
                <img src={logo} className="contacts-logo" />
                <p>MARINI MEDICINA DENTÁRIA</p>
                <p>Rua da Constituição, 402 | 4200-192 Porto</p>
                <p>TELF. 225 505 043 | 962 693 181 | 912 639 181</p>
                <p>EMAIL <a href="mailto:clinicadentariamarini@gmail.com">clinicadentariamarini@gmail.com</a></p>
                <a target="blank" href="http://maps.google.com/maps?f=q&amp;source=embed&amp;hl=pt-PT&amp;geocode=&amp;q=Rua+da+Constitui%C3%A7%C3%A3o+402,+Porto,+Portugal&amp;aq=0&amp;oq=Rua+da+Constitui%C3%A7%C3%A3o,+402&amp;sll=40.396764,-3.713379&amp;sspn=9.61761,19.753418&amp;ie=UTF8&amp;hq=&amp;hnear=Rua+da+Constitui%C3%A7%C3%A3o+402,+Porto,+Portugal&amp;ll=41.161756,-8.603009&amp;spn=0.009289,0.01929&amp;t=m&amp;z=14&amp;iwloc=A">Ver mapa maior</a>
            </div>
        </div>
        <div className="contacts">
            <div className="contacts-googlemap">
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2988.883925964934!2d-8.771976384327244!3d41.48511609789343!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd24491557e36541%3A0x85a44ff1bd6659d6!2sPedro+Marini+Cl.+Med+Dent%C3%A1ria!5e0!3m2!1sen!2spt!4v1552604766160"></iframe>
            </div>
            <div className="contacts-details">
                <img src={logo} className="contacts-logo" />
                <p>CLÍNICA DE MEDICINA DENTÁRIA APÚLIA</p>
                <p>Rua da Casa do Povo, 32 | 4740-079 Apúlia</p>
                <p>TELF. 253 982 282 | 915 108 369 | 925 319 696</p>
                <p>EMAIL <a href="mailto:clinicadentariamarini@gmail.com">clinicadentariamarini@gmail.com</a></p>
                <a target="blank" href="http://maps.google.com/maps?f=q&amp;source=embed&amp;hl=pt-PT&amp;geocode=&amp;q=Rua+da+Constitui%C3%A7%C3%A3o+402,+Porto,+Portugal&amp;aq=0&amp;oq=Rua+da+Constitui%C3%A7%C3%A3o,+402&amp;sll=40.396764,-3.713379&amp;sspn=9.61761,19.753418&amp;ie=UTF8&amp;hq=&amp;hnear=Rua+da+Constitui%C3%A7%C3%A3o+402,+Porto,+Portugal&amp;ll=41.161756,-8.603009&amp;spn=0.009289,0.01929&amp;t=m&amp;z=14&amp;iwloc=A">Ver mapa maior</a>
            </div>
        </div>
    </div>
)

export default Contacts;