import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';

import slider1 from "../images/slider-1-conventions.png";
import slider2 from "../images/slider-2-clinic.jpg";
import slider3 from "../images/slider-3-entrance.jpg";

const Slider = () => {
    return (
        <Carousel
            showArrows={false}
            showStatus={false}
            showThumbs={false}
            autoPlay={true}
            interval={6000}
            infiniteLoop={true}
            transitionTime={500}
        >
            <img src={slider1} />
            <img src={slider2} />
            <img src={slider3} />
        </Carousel>
    );
}

export default Slider;