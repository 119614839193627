import React from "react";
import "./footer.css";

export default class Footer extends React.Component {
    public render() {
        return (
            <div className="footer">
                <div style={{ display: "flex", margin: "0 auto", maxWidth: "1000px" }}>
                    <div style={{ flex: "1", textAlign: "end", marginRight: "15px" }}>
                        <p>Porto</p>
                        <p>MARINI MEDICINA DENTÁRIA</p>
                        <p>Rua da Constituição, 402 | 4200-192 Porto</p>
                        <p>TELF. 225 505 043 | 962 693 181 | 912 639 181</p>
                    </div>
                    <div style={{ flex: "1", textAlign: "start" }}>
                        <p>Apúlia</p>
                        <p>CLÍNICA DE MEDICINA DENTÁRIA APÚLIA</p>
                        <p>Rua da Casa do Povo, 32 | 4740-079 Apúlia</p>
                        <p>TELF. 253 982 282 | 915 108 369 | 925 319 696</p>
                    </div>

                </div>
                <div><p>EMAIL <a href="mailto:clinicadentariamarini@gmail.com">clinicadentariamarini@gmail.com</a></p></div>
                <div>MARINI MEDICINA DENTÁRIA 2019. All Rights Reserved.</div>
            </div>
        );
    }
}